// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { bgBackground } from '../../../../assets/img/login'
import {
  Jumbotron,
  Table,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Card,
  Container
} from 'reactstrap'

// @Dependencies
import {
  TournamentRoundStart,
  TournamentRoundReport,
  TournamentRoundReset,
  TournamentMatchUpdate,
  TournamentFinish,
  getTournamentInfo,
  getUser,
  getTournamentRound,
  getTournamentPlayers
} from '../../../Redux/Actions'
import {
  LoadingSVG,
  Button,
  Alert,
  Toggle,
  Select,
  Avatar
} from '../../../Components/UI'
import { axiosAbort } from '../../../Services'
import TournamentMenu from '../TournamentMenu'
import TournamentInfo from './TournamentInfo'

import RoundViewPdf from './view_print'
import { StoreAlertPoints } from './StoreAlertPoints'
import RoundVPdfBallot from './view_print_ballot'
import { GameRaces } from './races'

// @Component
class TournamentRoundView extends Component {
  state = {
    alertContent: '',
    buttonBack: '',
    alertState: 'info',
    loading: true,
    loadingResult: false,
    havePermissions: false,
    earlyClose: false,
    modal: false,
    modalPair: false,
    modalRound: false,
    modalEnd: false,
    matchPair: {},
    tournament: {},
    round: {},
    matchPendings: [],
    matchReport: null,
    matchReportData: {
      _playerWins: 0,
      _opponentWins: 0,
      dropPlayer: false,
      dropOpponent: false
    },
    localSearch: '',
    resultOptions: [
      { id: 1, name: '0 partidas ganadas' },
      { id: 2, name: '1 partida ganada' },
      { id: 3, name: '2 partidas ganadas' }
    ],
    dropdownMenu: false,
    renderPdf: this.props.match.params.hasOwnProperty('pdf'),
    renderTables: this.props.match.params.hasOwnProperty('tables'),
    // Modal TOP
    modalTop: false,
    topPlayers: 0,
    topPlayersList: [],
    navigation: {
      prev: {},
      next: {}
    },
    Players: []
  }

  /**
   * - Al iniciar busca los datos de la ronda
   * - Si no tengo permisos para editar el torneo no vemos el menu y administración
   */
  componentDidMount () {
    this.searchRound()
  }
  componentWillUnmount () {
    axiosAbort()
  }
  async searchRound () {
    const { getUser, getTournamentRound, match } = this.props
    await getUser()
    const roundInfo = await getTournamentRound(match.params.roundId)

    if (roundInfo.status.success) {
      const { loggedUser } = this.props

      let havePermissions =
        loggedUser.user && loggedUser.user.Store
          ? roundInfo.Tournament.Store.id === loggedUser.user.Store.id ||
            loggedUser.isAdmin
          : roundInfo.Tournament.TournamentOrganizers.some(organizer => {
              if (loggedUser.user) {
                return organizer.personId === loggedUser.user.id
              }
              return false
            })
      if (!havePermissions) {
        havePermissions = roundInfo.Tournament.TournamentOrganizers.some(
          organizer => {
            if (loggedUser.user) {
              return organizer.personId === loggedUser.user.id
            }
            return false
          }
        )
      }

      const totalRounds = roundInfo.Tournament.TotalTournamentRounds.length
      const pairingPlayers = roundInfo.TournamentMatches.reduce((
        acc,
        match
      ) => {
        return acc.concat([match.playerId, match.opponentId])
      }, [])
      const allFreePlayers = []
      const freePlayers = new Map()
      const freeOpponents = new Map()
      /**
       * Rondas suizas validamos que no hayan oponentes mal asignados o falte alguno
       */
      let moreRounds = false
      if (roundInfo.TournamentRoundType.id === 1) {
        roundInfo.Tournament.TournamentPlayers.map(player => {
          if (!pairingPlayers.includes(player.id) && !player.drop) {
            allFreePlayers.push(player)
            freePlayers.set(player.id, player)
            freeOpponents.set(player.id, player)
          }
          return true
        })
        moreRounds = roundInfo.Tournament.rounds > totalRounds
      } else {
        moreRounds = roundInfo.TournamentMatches.length > 1
      }

      const topPlayersList = [2, 4, 8, 16, 32]
        .map(t => {
          return { id: t, name: `Top ${t}` }
        })
        .filter(f => {
          return (
            f.id <= roundInfo.Tournament.TournamentPlayers.length &&
            f.id <= roundInfo.Tournament.TournamentType.maxTopPlayers
          )
        })

      const rounds = roundInfo.Tournament.TotalTournamentRounds
      const navigation = {
        prev: rounds[roundInfo.sortOrder - 2]
          ? rounds[roundInfo.sortOrder - 2]
          : {},
        next: rounds[roundInfo.sortOrder] ? rounds[roundInfo.sortOrder] : {}
      }
      const { Players } = await this.props.getTournamentPlayers(
        roundInfo.Tournament.id
      )
      this.setState({
        topPlayersList,
        havePermissions,
        round: roundInfo,
        tournament: roundInfo.Tournament,
        loading: false,
        totalRounds,
        moreRounds,
        allFreePlayers,
        freePlayers,
        freeOpponents,
        navigation,
        Players
      })
      this.checkMatchPendings()
    } else {
      this.setState({
        buttonBack: '/tournaments',
        alertContent: `No se pudieron encontrar los datos de la ronda.`
      })
    }

    this.checkWinner()
  }
  checkMatchPendings () {
    const { round } = this.state
    const matchPendings = round.TournamentMatches.filter(match => {
      return !match.reported
    })
    this.setState({ matchPendings })
  }

  checkWinner () {
    const { Players } = this.state
    const maxPoint = Math.max(
      ...Players.map(s => {
        return s.points
      })
    )
    const topPlayers = [...Players].filter(s => {
      return s.points === maxPoint
    })

    const top1 = topPlayers.find(x => x)
    this.setState({
      earlyClose: topPlayers.length === 1,
      TournamentWinner: top1
    })
  }

  starNewRound = async (endTournament, earlyClose = false) => {
    this.checkMatchPendings()
    const { matchPendings, moreRounds, round } = this.state
    if (matchPendings.length && !earlyClose) {
      this.setState({
        alertContent: `Aún hay ${
          matchPendings.length
        } duelos pendientes para reportar.`
      })
    } else {
      this.setState({
        loading: true,
        alertContent:
          moreRounds && endTournament
            ? 'Estamos iniciando una nueva ronda'
            : 'Estamos dando por finalizado el torneo.'
      })
      const { TournamentRoundStart } = this.props
      const data = {
        tournamentId: this.state.tournament.id,
        addNew: moreRounds && endTournament,
        earlyClose
      }
      if (round.TournamentRoundType.id === 2) {
        data.top = round.Tournament.TournamentType.maxTopPlayers
      }
      const result = await TournamentRoundStart(data)
      if (result.status.success) {
        this.setState({
          alertContent: result.status.name
        })
        setTimeout(() => {
          window.location.href = moreRounds
            ? result.Round.urlView
            : result.Round.urlStanding
        }, 2000)
      } else {
        this.setState({
          alertContent: `${result.status.name} - ${result.status.detail}`
        })

        setTimeout(() => {
          this.setState({
            loading: false
          })
        }, 3000)
      }
    }
  }

  /**
   * Cierra la alerta
   */
  handleDismissAlert = async e => {
    e.preventDefault()
    this.setState({
      alertContent: ''
    })
  }

  toggleMenu = () => {
    this.setState({
      dropdownMenu: !this.state.dropdownMenu
    })
  }
  toggleModalPair = async match => {
    this.setState({
      modalPair: !this.state.modalPair,
      matchPair: match
    })
  }

  /**
   * Actualiza un match en la base de datos
   */
  updateMatch = async e => {
    e.preventDefault()
    const { matchReport, matchReportData } = this.state
    const { TournamentRoundReport } = this.props

    matchReportData.id = matchReport.id
    matchReportData.winnerId = 0
    matchReportData.loserId = 0
    matchReportData.draw = true
    matchReportData.roundId = matchReport.roundId
    matchReportData.table = matchReport.table
    matchReportData.tournamentId = matchReport.tournamentId

    if (matchReportData._playerWins === matchReportData._opponentWins) {
      matchReportData.winnerVictories = matchReportData._playerWins - 1
      matchReportData.loserVictories = matchReportData._opponentWins - 1

      matchReportData.winnerDefeats = matchReportData._opponentWins - 1
      matchReportData.loserDefeats = matchReportData._playerWins - 1

      matchReportData.winnerDraws = 0
      matchReportData.loserDraws = 0
    }
    /**
     * Calcula el ganador y Perdedor
     */
    if (matchReportData._playerWins > matchReportData._opponentWins) {
      matchReportData.winnerId = matchReport.Player.id
      matchReportData.loserId = matchReport.Opponent.id
      matchReportData.draw = false
    } else if (matchReportData._opponentWins > matchReportData._playerWins) {
      matchReportData.winnerId = matchReport.Opponent.id
      matchReportData.loserId = matchReport.Player.id
      matchReportData.draw = false
    }

    /**
     * Ordena las partidas perdidas
     */
    if (matchReportData.winnerId === matchReport.Player.id) {
      matchReportData.winnerVictories = matchReportData._playerWins - 1
      matchReportData.winnerDefeats = matchReportData._opponentWins - 1
      matchReportData.winnerDraws = 0
      matchReportData.loserVictories = matchReportData._opponentWins - 1
      matchReportData.loserDefeats = matchReportData._playerWins - 1
      matchReportData.loserDraws = 0
    }

    if (matchReportData.winnerId === matchReport.Opponent.id) {
      matchReportData.winnerVictories = matchReportData._opponentWins - 1
      matchReportData.winnerDefeats = matchReportData._playerWins - 1
      matchReportData.winnerDraws = 0
      matchReportData.loserVictories = matchReportData._playerWins - 1
      matchReportData.loserDefeats = matchReportData._opponentWins - 1
      matchReportData.loserDraws = 0
    }

    const result = await TournamentRoundReport(matchReportData)
    if (result.status.success) {
      this.searchRound()
      this.setState({
        matchReportData: {
          _playerWins: 0,
          _opponentWins: 0,
          dropPlayer: false,
          dropOpponent: false,
          draw: true
        },
        modal: false,
        alertContent: `El resultado de la mesa ${
          result.Match.table
        } fue reportado correctamente.`,
        alertState: 'success'
      })
    }
  }

  /**
   * Cambia los resultados de una mesa
   */
  editMatch = match => {
    const matchReportData = {
      _playerWins: 0,
      _opponentWins: 0,
      dropPlayer: false,
      dropOpponent: false,
      roundId: match.roundId,
      table: match.table,
      tournamentId: match.tournamentId
    }
    if (match.draw) {
      matchReportData._playerWins = match.winnerVictories + 1
      matchReportData._opponentWins = match.winnerVictories + 1
    } else if (match.Winner.id === match.Player.id) {
      matchReportData._playerWins = match.winnerVictories + 1
      matchReportData._opponentWins = match.loserVictories + 1
    } else if (match.Winner.id === match.Opponent.id) {
      matchReportData._playerWins = match.loserVictories + 1
      matchReportData._opponentWins = match.winnerVictories + 1
    }

    matchReportData.dropPlayer = match.dropPlayer === 1
    matchReportData.dropOpponent = match.dropOpponent === 1
    this.setState({
      matchReport: match,
      matchReportData: matchReportData,
      modal: true
    })
  }

  /**
   * Controla los cambios de estados de los inputs
   */
  onChange = async e => {
    e.preventDefault()
    const { target } = e

    let alertContent = ''
    if (
      ['playerWins', 'opponentWins', 'dropPlayer', 'dropOpponent'].includes(
        target.id
      )
    ) {
      const { matchReport, round } = this.state
      const matchReportData = {
        ...this.state.matchReportData,
        dropOpponent: this.state.matchReportData.dropOpponent,
        dropPlayer: this.state.matchReportData.dropPlayer,
        roundId: matchReport.roundId,
        table: matchReport.table,
        tournamentId: matchReport.tournamentId
      }
      if (target.id === 'playerWins') {
        matchReportData._playerWins = parseInt(target.value, 10)
      }
      if (target.id === 'opponentWins') {
        matchReportData._opponentWins = parseInt(target.value, 10)
      }

      if (target.id === 'dropPlayer') {
        matchReportData.dropPlayer = !matchReportData.dropPlayer
      }
      if (target.id === 'dropOpponent') {
        matchReportData.dropOpponent = !matchReportData.dropOpponent
      }

      /**
       * Error al reportar empates a 2
       */
      if (
        matchReportData._playerWins === 3 &&
        matchReportData._opponentWins === 3
      ) {
        alertContent = 'No puedes reportar un resultado empatado a 2.'
      }

      /**
       * Si es ronda de eliminación directa entonces no permitimos empates
       */
      if (
        round.TournamentRoundType.id === 2 &&
        matchReportData._playerWins === matchReportData._opponentWins
      ) {
        alertContent =
          'En rondas de eliminación directa no puedes reportar un empate.'
      }
      this.setState({ matchReportData })
    }
    if (['pairPlayer', 'pairOpponent'].includes(target.id)) {
      const { matchPair, freePlayers, freeOpponents } = this.state
      if (target.id === 'pairPlayer') {
        matchPair.playerId = parseInt(target.value, 10)
          ? parseInt(target.value, 10)
          : 0
        const newFreeMap = new Map()
        // eslint-disable-next-line no-unused-vars
        const tmp = [...freePlayers.values()].map(player => {
          player = {
            ...player,
            assigned: matchPair.playerId && player.id === matchPair.playerId
          }
          newFreeMap.set(player.id, player)
          return false
        })
        this.setState({
          freeOpponents: newFreeMap
        })
      }

      if (target.id === 'pairOpponent') {
        matchPair.opponentId = parseInt(target.value, 10)
          ? parseInt(target.value, 10)
          : 0
        const newFreeMap = new Map()
        // eslint-disable-next-line no-unused-vars
        const tmp = [...freeOpponents.values()].map(player => {
          player = {
            ...player,
            assigned: matchPair.opponentId && player.id === matchPair.opponentId
          }
          newFreeMap.set(player.id, player)
          return false
        })
        this.setState({
          freePlayers: newFreeMap
        })
      }

      this.setState({
        matchPair
      })
    }

    if (target.id === 'topPlayers') {
      const topPlayers = parseInt(target.value, 10)
      this.setState({ topPlayers })
    }
    this.setState({ alertContent, alertState: 'warning' })
  }

  updateMatchPair = async e => {
    e.preventDefault()
    const {
      matchPair,
      allFreePlayers,
      freePlayers,
      freeOpponents,
      round
    } = this.state
    const { TournamentMatchUpdate } = this.props
    const resolve = await TournamentMatchUpdate(matchPair)
    if (resolve.status.success) {
      matchPair.alertContent = ''
      const mapFreePlayers = new Map()
      const mapFreeOpponents = new Map()
      // eslint-disable-next-line no-unused-vars
      const _tmp1 = [...freePlayers.values()].map(player => {
        player = {
          ...player,
          assigned:
            player.id === resolve.Match.playerId ||
            player.id === resolve.Match.opponentId
        }
        mapFreePlayers.set(player.id, player)
        return false
      })

      // eslint-disable-next-line no-unused-vars
      const _tmp2 = [...freeOpponents.values()].map(player => {
        player = {
          ...player,
          assigned:
            player.id === resolve.Match.playerId ||
            player.id === resolve.Match.opponentId
        }
        mapFreeOpponents.set(player.id, player)
        return false
      })

      const newFreePlayers = [...allFreePlayers.values()]
        .map(player => {
          return player
        })
        .filter(f => {
          return ![resolve.Match.playerId, resolve.Match.opponentId].includes(
            f.id
          )
        })
      round.TournamentMatches = round.TournamentMatches.map(m => {
        if (m.id === resolve.Match.id) {
          m = resolve.Match
        }
        return m
      })

      this.setState({
        freePlayers: mapFreePlayers,
        freeOpponents: mapFreeOpponents,
        allFreePlayers: newFreePlayers,
        matchPair: {},
        round,
        modalPair: false
      })

      // Quitar reload y fixear freeOpponents asignados para que no se repitan
      window.location.reload()
    } else {
      matchPair.alertContent = `${resolve.status.name} - ${
        resolve.status.detail
      }`
      this.setState({
        matchPair
      })
    }
  }

  clearMatch = async (match, reset) => {
    const { round, allFreePlayers, freePlayers, freeOpponents } = this.state

    if (reset) {
      const resolve = await this.props.TournamentMatchUpdate({
        ...match,
        reset: true,
        playerId: 0,
        opponentId: 0
      })
      if (resolve.status.success) {
        /** deja sin jugadores el match */
        round.TournamentMatches = round.TournamentMatches.map(m => {
          const empty = {
            Person: {
              id: -1,
              fullName: ''
            }
          }
          const Player = m.id === match.id ? empty : m.Player
          const Opponent = m.id === match.id ? empty : m.Opponent
          return {
            ...m,
            playerId: m.id === match.id ? 0 : m.playerId,
            opponentId: m.id === match.id ? 0 : m.opponentId,
            reported: m.id === match.id ? 0 : m.reported,
            Player,
            Opponent
          }
        })

        freePlayers.set(match.Player.id, match.Player)
        match.Opponent && freePlayers.set(match.Opponent.id, match.Opponent)

        freeOpponents.set(match.Player.id, match.Player)
        match.Opponent && freeOpponents.set(match.Opponent.id, match.Opponent)

        allFreePlayers.push(match.Player)
        allFreePlayers.push(match.Opponent)

        this.setState({
          allFreePlayers,
          freePlayers,
          freeOpponents,
          round
        })
      } else {
        window.alert('No se pudo limpiar el resultado')
      }
    }
  }

  onRoundReset = async resetType => {
    const { tournament, round } = this.state
    const { TournamentRoundReset, history } = this.props
    const data = {
      tournamentId: tournament.id,
      roundId: round.id,
      resetType
    }
    const reset = [
      '',
      'Borrar toda la ronda',
      'Limpiar Standing Ronda',
      'Borrar resultados'
    ]
    if (
      window.confirm(
        `¿Estás seguro de querer ¿${
          reset[resetType]
        }?, esta acción es irreversible.`
      )
    ) {
      this.setState({
        loading: true,
        alertContent: 'Estamos actualizando tu ronda.'
      })
      const result = await TournamentRoundReset(data)
      if (result.status.success) {
        if (resetType === 1 || resetType === 2) {
          axiosAbort()
          history.push(tournament.urlView)
        } else {
          await this.searchRound()
        }
        this.setState({
          loading: false,
          alertContent: result.status.name,
          alertState: 'success  '
        })
      }
    }
  }

  toggleModal = type => {
    if (type === 'round' || type === 'tournamentEnd') {
      const { tournament, round, moreRounds } = this.state
      if (
        tournament.TournamentStatus.id === 4 &&
        round.TournamentRoundType.id === 1 &&
        (!moreRounds || type === 'tournamentEnd')
      ) {
        if (
          window.confirm(
            'Al dar por terminado el torneo, se cerrarán los resultados hasta la ronda actual.'
          )
        ) {
          this.starNewRound(false, false)
        }
      } else {
        this.starNewRound(true, false)
        this.setState({
          alertContent: ''
        })
      }
    } else if (type === 'tournamentearlyClose') {
      if (window.confirm('Ya hay un ganador. ¿Quieres terminar el torneo?.')) {
        this.starNewRound(false, true)
      }
    } else {
      this.setState({
        alertContent: '',
        modalTop: !this.state.modalTop
      })
    }
  }

  /**
   * Inicia las rondas de eliminación directa
   */
  initTop = async e => {
    e.preventDefault()
    const { topPlayers } = this.state
    const { TournamentRoundStart } = this.props
    const data = {
      tournamentId: this.state.tournament.id,
      addNew: true,
      top: topPlayers
    }
    let alertContent = ''
    if (topPlayers) {
      this.setState({
        loading: true,
        alertContent: 'Estamos iniciando la ronda de elminación directa'
      })
      const result = await TournamentRoundStart(data)
      if (result.status.success) {
        alertContent = result.status.name
        setTimeout(() => {
          window.location.href = result.Round.urlView
        }, 2000)
      } else {
        alertContent = `${result.status.name} - ${result.status.detail}`
        setTimeout(() => {
          this.setState({
            loading: false
          })
        }, 3000)
      }
    } else {
      alertContent = 'Debes seleccionar el TOP del Torneo.'
    }
    this.setState({
      alertContent,
      alertState: 'danger'
    })
  }

  /**
   * Para terminar el torneo
   */
  toggleModalEnd = () => {
    this.setState({
      modalEnd: !this.state.modalEnd
    })
  }

  /**
   * Da por finalizado el torneo
   * - Se cambia el estado a 5 (si no lo está)
   * - Se procesan los datos del standing final y se publican en el ranking
   * - Se aplica el multiplicador del tipo de torneo a los resultados
   */
  endTournament = async () => {
    const { TournamentFinish, history } = this.props
    const { tournament } = this.state

    this.setState({
      loading: true,
      alertContent:
        'Estamos reportando los resultados de los jugadores al ranking.'
    })
    const response = await TournamentFinish(tournament.id)
    if (response.status.success) {
      this.setState({
        alertContent: response.status.detail,
        modal: {
          top: false,
          end: false
        }
      })
      setTimeout(() => {
        history.push(response.Tournament.urlView)
      }, 2000)
    } else {
      this.setState({
        loading: false,
        alertContent: response.status.detail
      })
    }
  }

  render () {
    const {
      alertContent,
      buttonBack,
      loading,
      renderPdf,
      renderTables,
      round,
      Players
    } = this.state
    if (loading)
      return <LoadingSVG message={alertContent} buttonBack={buttonBack} />
    return renderPdf ? (
      <RoundViewPdf round={round} players={Players} />
    ) : renderTables ? (
      <RoundVPdfBallot round={round} players={Players} />
    ) : (
      this.renderView()
    )
  }

  /**
   * Toggle con drops
   */
  onChangeModalToggle = e => {
    e.preventDefault()
    const target = e.target
    const { matchReportData } = this.state

    if (target.id === 'dropPlayer') {
      matchReportData.dropPlayer = !matchReportData.dropPlayer
    }
    if (target.id === 'dropOpponent') {
      matchReportData.dropOpponent = !matchReportData.dropOpponent
    }

    this.setState({ matchReportData })
  }

  renderView () {
    const {
      loading,
      havePermissions,
      alertContent,
      alertState,
      tournament,
      round,
      buttonBack,
      matchPendings,
      matchReport,
      matchPair,
      matchReportData,
      loadingResult,
      resultOptions,
      moreRounds,
      topPlayersList,
      Players,
      navigation
      // localSearch
    } = this.state
    const { t, match } = this.props
    if (loading)
      return <LoadingSVG message={alertContent} buttonBack={buttonBack} />
    const lastRoundId = tournament.TournamentRounds.map(r => {
      return r.id
    }).reduce(r => r)

    const allFreePlayers = this.state.allFreePlayers
    const freePlayers = [...this.state.freePlayers.values()].filter(f => {
      return !f.assigned
    })
    const freeOpponents = [...this.state.freeOpponents.values()].filter(f => {
      return !f.assigned
    })

    // no se poruqé estaba esto acá.
    /* const _players = Players.filter(p => {
      const personName = p.Person.name.toLowerCase()
      return personName.includes(localSearch.toLowerCase())
    }).map(p => p.id)
    round.TournamentMatches.filter(
      match =>
        _players.includes(match.playerId) || _players.includes(match.opponentId)
    ) */

    const matches = round.TournamentMatches

    return (
      <Wrapper>
        <Container>
          <div className='BaseRight__Form'>
            <TournamentMenu
              isMobile={this.props.isMobile}
              tournament={tournament}
              havePermissions={havePermissions}
              match={match}
              t={t}
            />
            <TournamentInfo
              tournament={tournament}
              isMobile={this.props.isMobile}
            />
            {alertContent.length > 0 && (
              <Alert close={this.handleDismissAlert} color={alertState}>
                {alertContent}
              </Alert>
            )}
            <Jumbotron className='Round'>
              <nav>
                {navigation.prev.id && (
                  <a href={navigation.prev.urlView} className='pull-left'>
                    <i className='fa fa-chevron-left' /> {navigation.prev.name}
                  </a>
                )}
                {navigation.next.id && (
                  <a href={navigation.next.urlView} className='pull-right'>
                    {navigation.next.name} <i className='fa fa-chevron-right' />
                  </a>
                )}
              </nav>
              <h3 className='display-5 text-center'>{round.name}</h3>
              <p className='text-center'>
                Estado: <b>{round.TournamentRoundStatus.name}</b>
              </p>
              {havePermissions && (
                <div className='buttons' style={{ textAlign: 'center' }}>
                  {!this.props.isMobile && (
                    <React.Fragment>
                      <UncontrolledTooltip
                        placement='top'
                        target={`print_pdf_tables`}>
                        Imprime las papeletas de la ronda
                      </UncontrolledTooltip>
                      <a
                        id='print_pdf_tables'
                        href={`${round.urlView}/pdf/tables`}
                        className='btn btn-warning'
                        target='_blank'
                        rel='noopener noreferrer'>
                        Imprimir Papeletas
                      </a>
                    </React.Fragment>
                  )}
                  {!this.props.isMobile && (
                    <React.Fragment>
                      <UncontrolledTooltip placement='top' target={`print_pdf`}>
                        Imprime la lista de: Jugador 1 vs Jugador 2
                      </UncontrolledTooltip>
                      <a
                        id='print_pdf'
                        href={`${round.urlView}/pdf`}
                        className='btn btn-primary'
                        target='_blank'
                        rel='noopener noreferrer'>
                        Imprimir Pairing
                      </a>
                    </React.Fragment>
                  )}
                  {round.TournamentRoundStatus.id === 2 && (
                    <React.Fragment>
                      <UncontrolledTooltip
                        placement='top'
                        target={`view_standing`}>
                        Ya que la ronda está terminada, revisa acá el Standing.
                      </UncontrolledTooltip>

                      <Button
                        id='view_standing'
                        text='Ver Standing'
                        link={round.urlStanding}
                        state='success'
                      />
                    </React.Fragment>
                  )}
                  <React.Fragment>
                    {lastRoundId === round.id &&
                      ![5, 6, 7, 8].includes(tournament.TournamentStatus.id) &&
                      (round.TournamentRoundStatus.id === 1 ? (
                        <React.Fragment>
                          <UncontrolledTooltip
                            placement='bottom'
                            target={`clear_results`}>
                            Puedes reiniciar los resultados de todas las
                            partidas. Esta acción limpia todos los resultados ya
                            reportados en esta ronda.
                          </UncontrolledTooltip>
                          <Button
                            id='clear_results'
                            onClick={() => this.onRoundReset(3)}
                            disabled={loading}
                            state='default'
                            text='Limpiar Resultados'
                          />
                          <UncontrolledTooltip
                            placement='bottom'
                            target={`delete_round`}>
                            Puedes eliminar la ronda, en este caso todos los
                            resultados se anularán y el emparejamiento de la
                            ronda actual se eliminará. Esta acción es
                            irreversible.
                          </UncontrolledTooltip>
                          <Button
                            id='delete_round'
                            onClick={() => this.onRoundReset(1)}
                            disabled={loading}
                            state='danger'
                            text='Borrar Ronda'
                          />
                        </React.Fragment>
                      ) : (
                        round.TournamentRoundStatus.id === 2 && (
                          <React.Fragment>
                            <Button
                              onClick={() => this.onRoundReset(3)}
                              disabled={loading}
                              text='Limpiar resultados'
                            />
                            <Button
                              onClick={() => this.onRoundReset(1)}
                              disabled={loading}
                              text='Borrar Ronda'
                            />
                          </React.Fragment>
                        )
                      ))}
                  </React.Fragment>
                </div>
              )}
              <Card>
                <Table
                  className={`Tournament__round__table ${
                    this.props.isMobile ? 'table-responsive' : ''
                  } table-bordered table-hover`}>
                  <thead>
                    <StoreAlertPoints
                      tournament={tournament}
                      isMobile={this.props.isMobile}
                      isAdmin={this.props.loggedUser.isAdmin}
                    />
                    <tr>
                      <th style={{ width: '5%' }}>#</th>
                      <th
                        style={{ width: '30%' }}
                        colSpan={!this.props.isMobile ? 2 : 1}>
                        Jugador
                      </th>
                      <th
                        style={{ width: '20%' }}
                        className='text-center'
                        colSpan={3}>
                        Resultado
                      </th>
                      <th
                        style={{ width: '30%' }}
                        colSpan={!this.props.isMobile ? 2 : 1}>
                        {' '}
                        Oponente
                      </th>
                      {round.TournamentRoundStatus.id === 1 && havePermissions && (
                        <th style={{ width: '10%' }}>
                          <i className='fa fa-cogs' />
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {matches.map(match => {
                      const winnerStyle = 'success myl'
                      const loserStyle = 'danger myl'
                      const drawStyle = 'info myl'
                      const pendingStyle = ''
                      const data = {
                        styles: {
                          player: '',
                          opponent: ''
                        },
                        results: {
                          player: '',
                          opponent: ''
                        }
                      }
                      if (match.reported) {
                        /**
                         * Empate
                         */
                        if (match.draw) {
                          data.styles = {
                            player: drawStyle,
                            opponent: drawStyle
                          }
                          data.results = {
                            player: match.winnerVictories,
                            opponent: match.loserVictories
                          }
                        } else {
                          if (
                            match.Winner &&
                            match.Winner.id === match.Player.id
                          ) {
                            data.styles = {
                              player: winnerStyle,
                              opponent: loserStyle
                            }
                            data.results = {
                              player: match.winnerVictories,
                              opponent: match.loserVictories
                            }
                          } else if (
                            match.Opponent &&
                            match.Winner.id === match.Opponent.id
                          ) {
                            data.styles = {
                              player: loserStyle,
                              opponent: winnerStyle
                            }
                            data.results = {
                              player: match.loserVictories,
                              opponent: match.winnerVictories
                            }
                          }
                        }
                      } else {
                        data.styles.player = pendingStyle
                        data.styles.opponent = pendingStyle
                      }
                      const _player = Players.filter(p => {
                        return p.id === match.playerId
                      })

                      const _opponent = Players.filter(p => {
                        return p.id === match.opponentId
                      })

                      return (
                        <tr key={match.id} className='Round__match'>
                          <td className='text-center'>{match.table}</td>
                          {!this.props.isMobile && (
                            <td className='text-center'>
                              {match.Player && (
                                <Avatar
                                  person={match.Player.Person}
                                  size={30}
                                  fontSize={12}
                                />
                              )}
                            </td>
                          )}
                          <td
                            className={`text-${
                              data.styles.player
                            } player flex ${
                              match.Player && match.Player.drop ? 'drop' : ''
                            }`}>
                            <React.Fragment>
                              <p className='name'>
                                {/* {match.Player.id} */}
                                {match.Player && match.Player.Person.fullName}
                                <br />
                                {_player.length > 0 && (
                                  <span className='pts'>
                                    {!tournament.isPresential &&
                                      tournament.showPersonalInfo === 1 && (
                                        <>
                                          <i className='fa fa-phone-square' />
                                          {match.Player.Person.phone}{' '}
                                        </>
                                      )}
                                    <i className='fa fa-tag' />
                                    {match.Player &&
                                      match.Player.Person.code}{' '}
                                    <i className='fa fa-info-circle' />
                                    {`(${_player[0].points}) pts.`}
                                    {this.props.loggedUser.isAdmin &&
                                      GameRaces.has(_player[0].raceId) && (
                                        <>
                                          <i className='fa fa-shield' />
                                          {GameRaces.get(_player[0].raceId)}
                                        </>
                                      )}
                                  </span>
                                )}
                              </p>
                            </React.Fragment>
                          </td>
                          <td className='text-center'>
                            <span
                              className={`result btn btn-${
                                data.styles.player
                              }`}>
                              {data.results.player}
                            </span>
                          </td>
                          <td className='text-center'>
                            <span>vs</span>
                          </td>
                          <td className='text-center'>
                            <span
                              className={`result btn btn-${
                                data.styles.opponent
                              }`}>
                              {' '}
                              {data.results.opponent}{' '}
                            </span>
                          </td>
                          <td
                            className={`text-${
                              data.styles.opponent
                            } opponent flex ${
                              match.Opponent && match.Opponent.drop
                                ? 'drop'
                                : ''
                            }`}>
                            <p className='name'>
                              {/* {match.Opponent.id} -- */}
                              {match.Opponent
                                ? match.Opponent.Person.fullName
                                : match.reported
                                ? '-- Bye --'
                                : ''}
                              <br />
                              {_opponent.length > 0 && (
                                <span className='pts'>
                                  {_opponent.length > 0 && (
                                    <span className='pts'>
                                      {!tournament.isPresential &&
                                        tournament.showPersonalInfo === 1 && (
                                          <>
                                            <i className='fa fa-phone-square' />
                                            {match.Opponent.Person.phone}{' '}
                                          </>
                                        )}
                                      <i className='fa fa-tag' />
                                      {match.Opponent &&
                                        match.Opponent.Person.code}{' '}
                                      <i className='fa fa-info-circle' />
                                      {`(${_opponent[0].points}) pts.`}
                                      {this.props.loggedUser.isAdmin &&
                                        GameRaces.has(_opponent[0].raceId) && (
                                          <>
                                            <i className='fa fa-shield' />
                                            {GameRaces.get(_opponent[0].raceId)}
                                          </>
                                        )}
                                    </span>
                                  )}
                                </span>
                              )}
                            </p>
                          </td>
                          {!this.props.isMobile && (
                            <td className='text-center'>
                              {match.Opponent && match.Opponent.Person && (
                                <Avatar
                                  person={match.Opponent.Person}
                                  size={30}
                                  fontSize={12}
                                />
                              )}
                            </td>
                          )}
                          {round.TournamentRoundStatus.id === 1 &&
                            havePermissions && (
                              <td className='text-center'>
                                {match.Opponent &&
                                  (match.reported ? (
                                    <i
                                      className='fa fa-pencil text-info btn'
                                      onClick={() => this.editMatch(match)}
                                    />
                                  ) : (
                                    <React.Fragment>
                                      {!allFreePlayers.length && (
                                        <i
                                          className='fa fa-gear text-danger btn'
                                          onClick={() =>
                                            this.setState({
                                              alertContent: '',
                                              matchReport: match,
                                              matchReportData: {
                                                _playerWins:
                                                  round.TournamentRoundType
                                                    .id === 1
                                                    ? 1
                                                    : 0,
                                                _opponentWins:
                                                  round.TournamentRoundType
                                                    .id === 1
                                                    ? 1
                                                    : 0,
                                                dropPlayer: false,
                                                dropOpponent: false,
                                                draw: true
                                              },
                                              modal: true
                                            })
                                          }
                                        />
                                      )}
                                      {(match.playerId > 0 ||
                                        (match.opponentId > 0 &&
                                          !match.reported)) &&
                                        round.TournamentRoundType.id === 1 && (
                                          <i
                                            className='fa fa-user-o text-info btn'
                                            aria-hidden='true'
                                            onClick={() => {
                                              if (
                                                window.confirm(
                                                  'Al ajustar el pairing de esta mesa, los jugadores quedarán libres y deberás emparejarlos de forma manual. ¿Estás seguro?'
                                                )
                                              ) {
                                                this.clearMatch(match, true)
                                              }
                                            }}
                                          />
                                        )}
                                    </React.Fragment>
                                  ))}
                                {(!match.playerId ||
                                  (!match.opponentId && !match.reported)) && (
                                  <i
                                    className='fa fa-pencil-square-o btn'
                                    aria-hidden='true'
                                    onClick={() => this.toggleModalPair(match)}
                                  />
                                )}
                              </td>
                            )}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Card>
              {havePermissions && (
                <div className='text-center'>
                  {round.TournamentRoundStatus.id === 1 && (
                    <React.Fragment>
                      {allFreePlayers.length > 0 ? (
                        <Jumbotron>
                          <h3 className='text-danger'>
                            Hay jugadores sin mesas asignadas
                          </h3>
                          <p>
                            Para continuar con la ronda debes ajustar el
                            emparejamiento.
                          </p>
                          <ol className='text-left'>
                            {allFreePlayers
                              .filter(f => {
                                return !f.assigned
                              })
                              .map((player, key) => {
                                return (
                                  <li key={key}>
                                    {player.id} {player.Person.fullName}
                                  </li>
                                )
                              })}
                          </ol>
                        </Jumbotron>
                      ) : (
                        <React.Fragment>
                          <div className='buttons'>
                            {tournament.TournamentType.maxTopPlayers > 0 &&
                              [3, 4].includes(tournament.TournamentStatus.id) &&
                              !moreRounds &&
                              round.TournamentRoundType.id === 1 && (
                                <Button
                                  text='Iniciar top'
                                  disabled={matchPendings.length > 0}
                                  state='default'
                                  onClick={() => this.toggleModal('top')}
                                />
                              )}
                            {/* Siguiente Ronda o Terminar Torneo */}
                            <Button
                              disabled={matchPendings.length > 0}
                              text={
                                moreRounds
                                  ? 'Siguiente Ronda'
                                  : round.TournamentRoundType.id === 1
                                  ? 'Terminar Rondas Suizas'
                                  : 'Terminar Rondas Eliminación Directa'
                              }
                              state={moreRounds ? 'primary' : 'danger'}
                              onClick={() => this.toggleModal('round')}
                              style={{
                                width:
                                  round.TournamentRoundType.id === 1 ? 200 : 300
                              }}
                            />
                            {tournament.TournamentStatus.id === 4 &&
                              moreRounds &&
                              round.TournamentRoundType.id === 1 && (
                                <Button
                                  disabled={matchPendings.length > 0}
                                  text={'Terminar Torneo'}
                                  state={'danger'}
                                  onClick={() =>
                                    this.toggleModal('tournamentEnd')
                                  }
                                  style={{
                                    width:
                                      round.TournamentRoundType.id === 1
                                        ? 200
                                        : 300
                                  }}
                                />
                              )}
                          </div>
                          {matchPendings.length > 0 && (
                            <React.Fragment>
                              <p>
                                Debes reportar todos los resultados antes de{' '}
                                {moreRounds
                                  ? `iniciar una nueva ronda ${
                                      tournament.TournamentStatus.id === 4
                                        ? 'o Terminar el torneo.'
                                        : ''
                                    }`
                                  : `terminar el torneo.`}
                              </p>
                              {this.state.earlyClose && (
                                <Card body>
                                  <h2>
                                    Este torneo ya tiene un jugador con puntaje
                                    máximo
                                  </h2>
                                  <p>
                                    Puedes dar por terminado el torneo de forma
                                    anticipada, el torneo se cerrará con las
                                    posiciones de la ronda anterior.
                                  </p>
                                  <h2>
                                    <Avatar
                                      person={
                                        this.state.TournamentWinner.Person
                                      }
                                    />{' '}
                                    {
                                      this.state.TournamentWinner.Person
                                        .fullName
                                    }
                                  </h2>
                                  <Button
                                    text='Terminar Torneo'
                                    style={{ margin: 'auto' }}
                                    onClick={() =>
                                      this.toggleModal('tournamentearlyClose')
                                    }
                                    disabled={!this.state.earlyClose}
                                  />
                                </Card>
                              )}
                            </React.Fragment>
                          )}
                          {!matchPendings.length > 0 &&
                            tournament.TournamentStatus.id !== 4 && (
                              <p>
                                Ya puedes iniciar la siguiente ronda del torneo
                              </p>
                            )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}

                  {/**
                   * Reportarmos el torneo
                   */
                  tournament.TournamentStatus.id === 5 && (
                    <Card body>
                      <h2>El torneo ha concluido. Ahora puedes reportarlo.</h2>
                      <p>
                        Recuerda que una vez reportado no podrás hacer cambios
                      </p>
                      <Button
                        text={
                          tournament.TournamentStatus.id !== 5
                            ? 'Terminar Torneo'
                            : 'Reportar torneo'
                        }
                        state='danger'
                        onClick={() => this.toggleModalEnd()}
                        style={{ margin: 'auto' }}
                      />

                      {/* Modal REPORTAR TORNEO */}
                      <Modal
                        isOpen={this.state.modalEnd}
                        toggle={() => this.toggleModalEnd()}>
                        <ModalHeader toggle={() => this.toggleModalEnd()}>
                          Gestión de Torneo
                        </ModalHeader>
                        <ModalBody>
                          <p>
                            Al confirmar <b>"Reportar Torneo"</b> se dará por
                            finalizado. Y no podrás hacer cambios en el evento.
                          </p>
                          <p>
                            Los puntajes obtenidos por los jugadores serán
                            reportados al ranking general.
                          </p>
                          <span className='text-info'>
                            Torneo {tournament.TournamentType.name}
                          </span>
                          <ul>
                            <li>
                              Por cada ronda ganada sumará{' '}
                              {tournament.TournamentType.winnerPoints *
                                tournament.TournamentType.multiplier}{' '}
                              puntos al ranking
                            </li>
                            <li>
                              Por cada ronda perdida sumará{' '}
                              {tournament.TournamentType.loserPoints *
                                tournament.TournamentType.multiplier}{' '}
                              puntos al ranking
                            </li>
                            <li>
                              Por cada ronda empatada sumará{' '}
                              {tournament.TournamentType.drawPoints *
                                tournament.TournamentType.multiplier}{' '}
                              puntos al ranking
                            </li>
                          </ul>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            state='default'
                            onClick={() => this.toggleModalEnd()}
                            text='Cancelar'
                          />
                          <Button
                            state='success'
                            onClick={this.endTournament}
                            text={'Reportar Torneo'}
                          />
                        </ModalFooter>
                      </Modal>
                    </Card>
                  )}
                </div>
              )}
            </Jumbotron>
          </div>

          {matchReport && (
            <WrapperModal>
              <Modal
                isOpen={this.state.modal}
                toggle={() =>
                  this.setState({
                    modal: false
                  })
                }>
                <ModalHeader toggle={() => this.setState({ modal: false })}>
                  Reportar Resultados
                </ModalHeader>
                <ModalBody>
                  {alertContent.length > 0 && (
                    <Alert close={this.handleDismissAlert} color={alertState}>
                      {alertContent}
                    </Alert>
                  )}
                  <p>
                    {matchReport.TournamentRound.name} - Mesa #
                    {matchReport.table}
                  </p>
                  <Table className='ReportResult table-bordered'>
                    <thead>
                      <tr>
                        <th colSpan={!this.props.isMobile ? 2 : 1}>Jugador</th>
                        <th>Resultados</th>
                        {matchReport.TournamentRound.TournamentRoundType.id ===
                          1 && <th>DROP?</th>}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {!this.props.isMobile && (
                          <td>
                            <Avatar
                              person={matchReport.Player.Person}
                              size={70}
                              fontSize={30}
                            />
                          </td>
                        )}
                        <td
                          className={
                            matchReportData._playerWins >
                            matchReportData._opponentWins
                              ? 'text-success'
                              : matchReportData._opponentWins ===
                                matchReportData._playerWins
                              ? 'text-info'
                              : 'text-danger'
                          }>
                          <h5>{matchReport.Player.Person.fullName}</h5>
                        </td>
                        <td className='player'>
                          <Select
                            inputSize={12}
                            disabled={loadingResult}
                            onChange={this.onChange}
                            name='playerWins'
                            options={resultOptions}
                            select={matchReportData._playerWins}
                            required
                          />
                        </td>
                        <td className='text-center'>
                          {round.TournamentRoundType.id === 1 && (
                            <Toggle
                              disabled={loadingResult}
                              name='dropPlayer'
                              checked={matchReportData.dropPlayer}
                              onChange={this.onChangeModalToggle}
                              value={'off'}
                            />
                          )}
                        </td>
                      </tr>
                      <tr>
                        {!this.props.isMobile && (
                          <td>
                            <Avatar
                              person={matchReport.Opponent.Person}
                              size={70}
                              fontSize={30}
                            />
                          </td>
                        )}
                        <td
                          className={
                            matchReportData._opponentWins >
                            matchReportData._playerWins
                              ? 'text-success'
                              : matchReportData._opponentWins ===
                                matchReportData._playerWins
                              ? 'text-info'
                              : 'text-danger'
                          }>
                          <h5>{matchReport.Opponent.Person.fullName}</h5>
                        </td>
                        <td className='player'>
                          <Select
                            inputSize={12}
                            disabled={loadingResult}
                            onChange={this.onChange}
                            name='opponentWins'
                            options={resultOptions}
                            select={matchReportData._opponentWins}
                            required
                          />
                        </td>
                        <td className='text-center'>
                          {round.TournamentRoundType.id === 1 && (
                            <Toggle
                              disabled={loadingResult}
                              name='dropOpponent'
                              checked={matchReportData.dropOpponent}
                              onChange={this.onChangeModalToggle}
                              value={'off'}
                            />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </ModalBody>
                <ModalFooter>
                  <Button
                    state='default'
                    onClick={() =>
                      this.setState({
                        modal: false
                      })
                    }
                    text='Cancelar'
                  />
                  <Button
                    disabled={
                      !matchReportData._playerWins ||
                      !matchReportData._opponentWins ||
                      (matchReportData._playerWins === 3 &&
                        matchReportData._opponentWins === 3) ||
                      (round.TournamentRoundType.id === 2 &&
                        matchReportData._playerWins ===
                          matchReportData._opponentWins)
                    }
                    state='success'
                    onClick={this.updateMatch}
                    text='Reportar'
                  />
                </ModalFooter>
              </Modal>
            </WrapperModal>
          )}

          {
            /* Modal pairing mesa */
            <WrapperModal>
              <Modal
                isOpen={this.state.modalPair}
                toggle={this.toggleModalPair}>
                <ModalHeader toggle={this.toggleModalPair}>
                  Ajustar jugadores en Mesa {matchPair.table}
                </ModalHeader>
                <ModalBody>
                  {matchPair.alertContent &&
                    matchPair.alertContent.length > 0 && (
                      <Alert>{matchPair.alertContent}</Alert>
                    )}
                  <Table className='ReportResult'>
                    <tbody>
                      <tr>
                        <td>Jugador 1:</td>
                        <td className='player'>
                          <Select
                            inputSize={12}
                            disabled={loadingResult}
                            onChange={this.onChange}
                            name='pairPlayer'
                            options={freePlayers.map(p => {
                              return {
                                id: p.id,
                                name: p.Person.fullName
                              }
                            })}
                            select={matchPair.playerId}
                            required
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Jugador 2:</td>
                        <td className='player'>
                          <Select
                            inputSize={12}
                            disabled={loadingResult}
                            onChange={this.onChange}
                            name='pairOpponent'
                            options={freeOpponents.map(p => {
                              return {
                                id: p.id,
                                name: p.Person.fullName
                              }
                            })}
                            select={matchPair.opponentId}
                            required
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </ModalBody>
                <ModalFooter>
                  <Button
                    state='default'
                    onClick={() => this.toggleModalPair(matchPair)}
                    text='Cancelar'
                  />
                  <Button
                    disabled={
                      matchPair.playerId === matchPair.opponentId ||
                      (!matchPair.playerId || !matchPair.opponentId)
                    }
                    state='primary'
                    onClick={this.updateMatchPair}
                    text='Emparejar'
                  />
                </ModalFooter>
              </Modal>
            </WrapperModal>
          }

          {/* Modal TOP */}
          {tournament.TournamentType.maxTopPlayers > 0 && (
            <Modal
              isOpen={this.state.modalTop}
              toggle={() => this.toggleModal('top')}>
              <ModalHeader toggle={() => this.toggleModal('top')}>
                Iniciar rondas de eliminación directa.
              </ModalHeader>
              <ModalBody>
                {alertContent.length > 0 && (
                  <Alert color={alertState} close={this.handleDismissAlert}>
                    {alertContent}
                  </Alert>
                )}
                <p>
                  Selecciona la cantidad de jugadores con la que quieres iniciar
                  las rondas de eliminación directa.
                </p>
                <Col md={12}>
                  <Select
                    name='topPlayers'
                    options={topPlayersList}
                    select={this.state.topPlayers}
                    onChange={this.onChange}
                  />
                </Col>
              </ModalBody>
              <ModalFooter>
                <Button
                  state='default'
                  onClick={() => this.toggleModal('top')}
                  text='Cancelar'
                />
                <Button
                  state='success'
                  onClick={this.initTop}
                  text={'Iniciar'}
                />
              </ModalFooter>
            </Modal>
          )}

          {/* Modal Siguiente Ronda o Fin */}
          <Modal
            isOpen={this.state.modalRound}
            toggle={() => this.toggleModal('round')}>
            <ModalHeader toggle={() => this.toggleModal('round')}>
              Gestión de Rondas
            </ModalHeader>
            <ModalBody>
              {tournament.TournamentStatus.id === 4 ? (
                <React.Fragment>
                  <p>
                    Si quieres, puedes terminar el torneo en esta ronda. No será
                    necesario reportar los resultados en rondas posteriores.{' '}
                  </p>
                  <p>
                    También puedes iniciar una nueva ronda{' '}
                    {round.TournamentRoundType.id === 1
                      ? 'suiza'
                      : 'de eliminación directa.'}
                    .
                  </p>
                </React.Fragment>
              ) : (
                <p>
                  Puedes continuar con las rondas{' '}
                  {round.TournamentRoundType.id === 1
                    ? 'suizas'
                    : 'de eliminación directa.'}
                  .
                </p>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                state='default'
                onClick={() => this.toggleModal('round')}
                text='Cancelar'
              />
              {tournament.TournamentStatus.id === 4 && (
                <Button
                  state='danger'
                  onClick={() => this.starNewRound(false, false)}
                  text={'Terminar Torneo'}
                />
              )}
              <Button
                state='success'
                onClick={() => this.starNewRound(true, false)}
                text={'Nueva Ronda Suiza'}
              />
            </ModalFooter>
          </Modal>
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
TournamentRoundView.propTypes = {
  loggedUser: PropTypes.object
}

/**
 * @Store Connection: connect
 * @Export Component
 */
const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      TournamentRoundStart,
      TournamentRoundReport,
      TournamentRoundReset,
      TournamentMatchUpdate,
      TournamentFinish,
      getTournamentInfo,
      getTournamentRound,
      getTournamentPlayers,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TournamentRoundView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'RoundDetails' })`
  background: url(${bgBackground('fondo1')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 20px 0px;

  .Tournament__round__table {
    margin-bottom: 0px;
    th {
      text-align: center;
      background-color: #eaeaea;
    }
  }
  .Round {
    padding-top: 20px;
    &__match {
      .btn.btn-danger.myl {
        background-color: #721616 !important;
        border-color: #721616 !important;
      }
      .btn.btn-success.myl {
        background-color: #33701c !important;
        border-color: #33701c !important;
      }
      .btn.btn-info.myl {
        background-color: #b5962c !important;
        border-color: #b5962c !important;
      }

      .text-danger.myl {
        color: #721616 !important;
      }
      .text-success.myl {
        color: #33701c !important;
      }
      .text-info.myl {
        color: #b5962c !important;
      }
      .player,
      .opponent {
        padding: 0.75rem 10px;
        width: 100%;
        border: 0px;
        .result {
          padding: 0px 10px;
          float: right;
        }
        &.drop {
          text-decoration: line-through;
          color: #828489 !important;
        }
        p {
          margin: 0px;
          &.name {
            font-size: 0.95rem;
            // text-transform: uppercase;
          }
          .pts {
            font-size: 0.65rem;
            i {
              font-size: 0.65rem;
              padding: 0px 5px 0px 5px;
            }
          }
        }
      }
      .opponent {
        .result {
          float: left;
          padding: 0px 10px;
          margin-right: 20px;
        }
      }
      .lead {
        button {
          margin: 0 auto !important;
        }
      }
      .vs {
        span.result {
          margin: 0px 10px;
        }
      }
    }
    .dropdown {
      float: right;
      margin: 20px;
    }
  }
`

const WrapperModal = styled.div.attrs({ className: 'CustomModal' })`
  .ReportResult {
    .result {
      color: red;
      font-size: 29px;
    }
  }
`
