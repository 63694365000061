// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Jumbotron, Table } from 'reactstrap'
import styled from 'styled-components'

const truncate = (string, limit) => {
  if (string.length > limit) return string.substring(0, limit) + '...'
  else return string
}

/**
 * Standing Print
 */
const StandingPrint = ({ round, standing, match, tournament }) => {
  return (
    <PdfWrapper>
      <Jumbotron className='Round' id='pdfRender'>
        <h2 className='text-center'>Torneo: "{tournament.name}"</h2>
        <h5 className='text-center'>Posiciones: "{round.name}"</h5>
        <Link
          to={round.urlView}
          className='btn btn-primary pull-right no-print'>
          Ver Resultados
        </Link>
        <Table className='table-bordered'>
          <thead>
            <tr>
              <th>#</th>
              <th>Jugador</th>
              <th>TOR</th>
              <th className='text-center'>Puntaje</th>
              <th className='text-center'>OMW</th>
              <th className='text-center'>PGW</th>
              <th className='text-center'>OGW</th>
            </tr>
          </thead>
          <tbody>
            {standing.map((player, key) => {
              return (
                <tr
                  key={player.id}
                  className={`Round__match ${
                    player.TournamentPlayer.drop ? 'text-danger' : ''
                  }`}>
                  <td className='text-center'> {key + 1}</td>
                  <td>
                    {truncate(player.TournamentPlayer.Person.fullName, 25)}{' '}
                    {round.TournamentRoundType.id === 2
                      ? `(${player.position})`
                      : ''}
                  </td>
                  <td>{player.TournamentPlayer.Person.code}</td>
                  <td className='text-center'>{player.points}</td>
                  <td className='text-center'>{player.omw}</td>
                  <td className='text-center'>{player.pgw}</td>
                  <td className='text-center'>{player.ogw}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {/* <p className='text-center'>Revisa los resultados en: {match.url}</p> */}
      </Jumbotron>
    </PdfWrapper>
  )
}

// @Proptypes
StandingPrint.propTypes = {
  round: PropTypes.object.isRequired,
  standing: PropTypes.array.isRequired
}

export default StandingPrint

const PdfWrapper = styled.div`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
  .no-print {
    display: none;
  }
  .jumbotron {
    background-color: transparent;
    padding: 0rem 2rem;
    font-size: 13px;
    th {
      background-color: #f8f9fa;
      vertical-align: middle;
    }
    table {
      td {
        padding: 2px !important;
      }
    }
  }
`
