// @External Dependencies
import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'
import { es } from 'date-fns/locale'
import { format, isValid } from 'date-fns'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { bgBackground } from '../../../../assets/img/login'
import {
  Jumbotron,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  Container
} from 'reactstrap'

// @Dependencies
import {
  TournamentRoundStart,
  TournamentFinish,
  getTournamentInfo,
  getTournamentRoundList,
  getUser
} from '../../../Redux/Actions'
import { LoadingSVG, Button, Select, Alert } from '../../../Components/UI'
import { axiosAbort } from '../../../Services'
import TournamentMenu from '../TournamentMenu'
import TournamentInfo from './TournamentInfo'
import { StoreAlertPoints } from './StoreAlertPoints'

// @Component
class TournamentRoundListView extends Component {
  state = {
    alertContent: '',
    buttonBack: '',
    alertState: 'warning',
    loading: true,
    enableButtons: false,
    havePermissions: false,
    topPlayers: 0,
    topPlayersList: [],
    tournament: {},
    rounds: [],
    modal: {
      top: false,
      end: false
    }
  }

  /**
   * - Al iniciar busca los datos de la ronda
   * - Si no tengo permisos para editar el torneo no vemos el menu y administración
   */
  async componentDidMount () {
    const {
      getUser,
      getTournamentInfo,
      getTournamentRoundList,
      match
    } = this.props
    await getUser()
    const reqTournament = await getTournamentInfo({
      ...match.params,
      listPlayers: true
    })

    if (reqTournament.status.success) {
      const { loggedUser } = this.props

      let enableButtons = false
      if (reqTournament.TournamentRounds.length) {
        enableButtons = reqTournament.TournamentRounds[0].statusId === 2
      }

      const havePermissions =
        loggedUser.user && loggedUser.user.Store
          ? reqTournament.storeId === loggedUser.user.Store.id ||
            loggedUser.isAdmin
          : reqTournament.TournamentOrganizers.some(organizer => {
              if (loggedUser.user) {
                return organizer.personId === loggedUser.user.id
              }
              return false
            })

      const resultRounds = await getTournamentRoundList(reqTournament.id)
      if (resultRounds.status.success) {
        const topPlayersList = [2, 4, 8, 16, 32]
          .map(t => {
            return { id: t, name: `Top ${t}` }
          })
          .filter(f => {
            return (
              f.id <= reqTournament.TournamentPlayers.length &&
              f.id <= reqTournament.TournamentType.maxTopPlayers
            )
          })
        this.setState({
          topPlayersList,
          rounds: resultRounds.Rounds,
          loading: false,
          havePermissions,
          tournament: reqTournament,
          enableButtons
        })
      } else {
        this.setState({
          alertContent: 'No se encontraron rondas creadas',
          buttonBack: reqTournament.urlAddPlayers
        })
      }
    } else {
      this.setState({
        buttonBack: '/tournaments',
        alertContent: `No se pudieron encontrar los datos de las rondas.<br /> "${
          reqTournament.status.detail
        }"`
      })
    }
  }
  componentWillUnmount () {
    axiosAbort()
  }

  /**
   * Controla los cambios de estados de los inputs
   */
  onChange = async e => {
    const { target } = e
    if (target.id === 'topPlayers') {
      const topPlayers = parseInt(target.value, 10)
      this.setState({ topPlayers })
    }
  }

  /**
   * Inicia las rondas de eliminación directa
   */
  initTop = async e => {
    e.preventDefault()
    const { topPlayers } = this.state
    const { TournamentRoundStart } = this.props
    const data = {
      tournamentId: this.state.tournament.id,
      addNew: true,
      top: topPlayers
    }
    let alertContent = ''
    if (topPlayers) {
      this.setState({
        loading: true,
        alertContent: 'Estamos iniciando la ronda de elminación directa'
      })
      const result = await TournamentRoundStart(data)
      if (result.status.success) {
        alertContent = result.status.name
        setTimeout(() => {
          window.location.href = result.Round.urlView
        }, 2000)
      } else {
        alertContent = `${result.status.name} - ${result.status.detail}`
        setTimeout(() => {
          this.setState({
            loading: false
          })
        }, 3000)
      }
    } else {
      alertContent = 'Debes seleccionar el TOP del Torneo.'
    }
    this.setState({
      alertContent
    })
  }

  /**
   * Da por finalizado el torneo
   * - Se cambia el estado a 5 (si no lo está)
   * - Se procesan los datos del standing final y se publican en el ranking
   * - Se aplica el multiplicador del tipo de torneo a los resultados
   */
  endTournament = async () => {
    const { TournamentFinish, history } = this.props
    const { tournament } = this.state

    this.setState({
      loading: true,
      alertContent:
        'Estamos reportando los resultados de los jugadores al ranking.'
    })
    const response = await TournamentFinish(tournament.id)
    if (response.status.success) {
      this.setState({
        alertContent: response.status.detail,
        modal: {
          top: false,
          end: false
        }
      })
      setTimeout(() => {
        history.push(response.Tournament.urlView)
      }, 2000)
    } else {
      this.setState({
        loading: false,
        alertContent: response.status.detail
      })
    }
  }

  toggleModal = type => {
    type === 'top' &&
      this.setState({
        modal: {
          ...this.state.modal,
          top: !this.state.modal.top
        }
      })

    type === 'end' &&
      this.setState({
        modal: {
          ...this.state.modal,
          end: !this.state.modal.end
        }
      })
  }

  render () {
    const {
      loading,
      havePermissions,
      alertContent,
      topPlayersList,
      tournament,
      rounds,
      buttonBack,
      enableButtons
    } = this.state
    const { t, match } = this.props
    if (loading)
      return <LoadingSVG message={alertContent} buttonBack={buttonBack} />

    return (
      <Wrapper>
        <Container>
          <div className='BaseRight__Form'>
            <TournamentMenu
              isMobile={this.props.isMobile}
              tournament={tournament}
              havePermissions={havePermissions}
              match={match}
              t={t}
            />
            <TournamentInfo
              tournament={tournament}
              isMobile={this.props.isMobile}
            />
            <Jumbotron className='Standing'>
              <h3 className='text-center'>Rondas del Torneo</h3>
              <p className='text-center'>
                Acá puedes revisar todas las rondas del Torneo.
              </p>
              <Table className='table-bordered'>
                <thead>
                  <StoreAlertPoints
                    tournament={tournament}
                    isMobile={this.props.isMobile}
                    isAdmin={this.props.loggedUser.isAdmin}
                  />
                  <tr>
                    <th>Nombre</th>
                    <th>Hora inicio ronda</th>
                    {!this.props.isMobile && <th>Estado</th>}
                    <th className='text-center'>Mesas</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {rounds.map(round => {
                    return (
                      <tr key={round.id} className='Standing__detail'>
                        <td>{round.name}</td>
                        <td>
                          {isValid(new Date(round.created)) &&
                            format(
                              new Date(round.created),
                              'EEEE dd MMM yyyy, HH:mm',
                              { locale: es }
                            )}{' '}
                          hrs
                        </td>
                        {!this.props.isMobile && (
                          <td>{round.TournamentRoundStatus.name}</td>
                        )}
                        <td className='text-center'>{round.totalMatches}</td>
                        <td className='buttons' style={{ margin: 'auto' }}>
                          <Link
                            to={round.urlView}
                            className='btn btn-info btn-sm'>
                            Ver <i className='fa fa-search' />
                          </Link>
                          {round.TournamentRoundStatus.id === 2 && (
                            <Link
                              to={round.urlStanding}
                              className='btn btn-primary btn-sm'>
                              Standing <i className='fa fa-list' />
                            </Link>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              {havePermissions &&
                enableButtons &&
                [4, 5].includes(tournament.TournamentStatus.id) && (
                  <Card body className='text-center'>
                    {tournament.TournamentStatus.id === 5 ? (
                      <h2>El torneo ha concluido. Ahora puedes reportarlo.</h2>
                    ) : (
                      <h2>Ya puedes dar por terminado el Torneo</h2>
                    )}
                    {tournament.TournamentStatus.id === 5 ? (
                      <p>
                        Recuerda que una vez reportado no podrás hacer cambios
                      </p>
                    ) : (
                      <p>
                        Para un cierre anticipado, puedes terminar el torneo
                        aquí.
                      </p>
                    )}
                    <div className='buttons'>
                      {tournament.TournamentType.maxTopPlayers > 0 &&
                        [4].includes(tournament.TournamentStatus.id) && (
                          <Button
                            text='Iniciar top'
                            state='primary'
                            onClick={() => this.toggleModal('top')}
                          />
                        )}
                      <Button
                        text={
                          tournament.TournamentStatus.id !== 5
                            ? 'Terminar Torneo'
                            : 'Reportar torneo'
                        }
                        state='danger'
                        onClick={() => this.toggleModal('end')}
                      />
                    </div>
                  </Card>
                )}
            </Jumbotron>
          </div>

          {/* Modal Fin Torneo */}
          <Modal
            isOpen={this.state.modal.end}
            toggle={() => this.toggleModal('end')}>
            <ModalHeader toggle={() => this.toggleModal('end')}>
              Gestión de Torneo
            </ModalHeader>
            <ModalBody>
              <p>
                Al confirmar <b>"Reportar Torneo"</b> se dará por finalizado. Y
                no podrás hacer cambios en el evento.
              </p>
              <p>
                Los puntajes obtenidos por los jugadores serán reportados al
                ranking general.
              </p>
              <span className='text-info'>
                Torneo {tournament.TournamentType.name}
              </span>
              <ul>
                <li>
                  Por cada ronda ganada sumará{' '}
                  {tournament.TournamentType.winnerPoints *
                    tournament.TournamentType.multiplier}{' '}
                  puntos al ranking
                </li>
                <li>
                  Por cada ronda perdida sumará{' '}
                  {tournament.TournamentType.loserPoints *
                    tournament.TournamentType.multiplier}{' '}
                  puntos al ranking
                </li>
                <li>
                  Por cada ronda empatada sumará{' '}
                  {tournament.TournamentType.drawPoints *
                    tournament.TournamentType.multiplier}{' '}
                  puntos al ranking
                </li>
              </ul>
            </ModalBody>
            <ModalFooter>
              <Button
                state='default'
                onClick={() => this.toggleModal('end')}
                text='Cancelar'
              />
              <Button
                state='success'
                onClick={this.endTournament}
                text={'Reportar Torneo'}
              />
            </ModalFooter>
          </Modal>
          {/* Modal TOP */}
          {tournament.TournamentType.maxTopPlayers > 0 && (
            <Modal
              isOpen={this.state.modal.top}
              toggle={() => this.toggleModal('top')}>
              <ModalHeader toggle={() => this.toggleModal('top')}>
                {' '}
                Iniciar rondas de eliminación directa{' '}
              </ModalHeader>
              <ModalBody>
                {alertContent.length > 0 && (
                  <Alert state={'warning'} close={this.handleDismissAlert}>
                    {alertContent}
                  </Alert>
                )}
                <p>
                  Selecciona la cantidad de jugadores con la que quieres iniciar
                  las rondas de eliminación directa.
                </p>
                <Select
                  name='topPlayers'
                  options={topPlayersList}
                  select={this.state.topPlayers}
                  onChange={this.onChange}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  state='default'
                  onClick={() => this.toggleModal('top')}
                  text='Cancelar'
                />
                <Button
                  state='success'
                  onClick={this.initTop}
                  text={'Iniciar'}
                />
              </ModalFooter>
            </Modal>
          )}
        </Container>
      </Wrapper>
    )
  }
}

// @Proptypes
TournamentRoundListView.propTypes = {
  loggedUser: PropTypes.object
}

/*
  @Store Connection: connect
  @Export Component
*/
const mapStateToProps = state => ({
  loggedUser: state.loggedUser
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      TournamentRoundStart,
      TournamentFinish,
      getTournamentInfo,
      getTournamentRoundList,
      getUser
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TournamentRoundListView))

// @Styles
const Wrapper = styled.div.attrs({ className: 'StandingDetails' })`
  background: url(${bgBackground('fondo1')}) 50% 0;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 20px 0px;
  .Standing {
    &__detail {
      a {
        margin: 0px 5px;
        i {
          font-size: 12px;
        }
      }
    }
  }
`
