// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Jumbotron, Table } from 'reactstrap'
import styled from 'styled-components'
import moment from 'moment'

const truncate = (string, limit) => {
  if (string.length > limit) return string.substring(0, limit) + '...'
  else return string
}
const dateFormat = 'DD MMMM Y - HH:mm\\h\\r\\s.'

/**
 * Standing Print
 */
const RoundViewPrint = ({ round, players }) => {
  const printPoints = round.TournamentRoundStatus.id === 1
  return (
    <PdfWrapper>
      <Jumbotron className='Round' id='pdfRender'>
        <h3 className='display-5 text-center'>{round.Tournament.name}</h3>
        <h6 className='display-5 text-center'>{round.name}</h6>
        <p className='text-center'>{moment(new Date()).format(dateFormat)}</p>
        <Table>
          <thead>
            <tr>
              <th style={{ width: '5%' }}>Mesa</th>
              <th style={{ width: '45%' }}>
                Jugador 1 {printPoints && <span>{'(Puntos)'}</span>}
              </th>
              <th style={{ width: '5%' }}>vs</th>
              <th style={{ width: '45%' }}>
                Jugador 2 {printPoints && <span>{'(Puntos)'}</span>}
              </th>
            </tr>
          </thead>
          <tbody>
            {players.map((player, key) => {
              const table = round.TournamentMatches.filter(f => {
                return f.playerId === player.id || f.opponentId === player.id
              })[0]

              const opponent = round.TournamentMatches.filter(f => {
                return f.playerId === player.id || f.opponentId === player.id
              }).map(match => {
                if (match.playerId === player.id) {
                  const points = players.filter(p => {
                    return p.id === match.opponentId
                  })
                  if (points.length > 0) {
                    match.Opponent.points = points[0].points
                  } else {
                    match.Opponent && (match.Opponent.points = 0)
                  }
                  return match.Opponent
                } else {
                  const points = players.filter(p => {
                    return p.id === match.playerId
                  })
                  if (points) {
                    match.Player.points = points[0].points
                  } else {
                    match.Player.points = 0
                  }
                  return match.Player
                }
              })[0]

              return (
                <tr key={key}>
                  <td className='text-center'>{table ? table.table : ''}</td>
                  <td className='player'>
                    {truncate(player.Person.fullName, 80)}{' '}
                    {printPoints && <span>{`(${player.points})`}</span>}
                  </td>
                  <td className='text-center'>-</td>
                  <td className='player'>
                    {opponent
                      ? truncate(opponent.Person.fullName, 80)
                      : player.drop
                      ? ' DROP '
                      : '-- BYE --'}
                    {printPoints && opponent && (
                      <span>{`(${opponent.points})`}</span>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Jumbotron>
    </PdfWrapper>
  )
}

// @Proptypes
RoundViewPrint.propTypes = {
  round: PropTypes.object.isRequired
}

export default RoundViewPrint

const PdfWrapper = styled.div`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;

  .jumbotron {
    background-color: transparent;
    padding: 0rem 1rem;
    font-size: 13px;
    width: 700px;
    table {
      td {
        padding: 2px !important;
        text-transform: uppercase;
      }
      th {
        background-color: #f8f9fa;
        vertical-align: middle;
      }
      span {
        float: right;
      }
    }
  }
`
