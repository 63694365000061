export const GameRaces = new Map([
  [1, 'Caballero'],
  [2, 'Guerrero'],
  [3, 'Sombra'],
  [4, 'Bestia'],
  [5, 'Dragón'],
  [6, 'Eterno'],
  [7, 'Faerie'],
  [8, 'Olímpico'],
  [9, 'Héroe'],
  [10, 'Titán'],
  [11, 'Desafiante'],
  [12, 'Defensor'],
  [13, 'Faraón'],
  [14, 'Sacerdote'],
  [15, 'Cazador'],
  [16, 'Licántropo'],
  [17, 'Vampiro'],
  [18, 'Chamán'],
  [19, 'Abominación'],
  [20, 'Bárbaro'],
  [21, 'Dios'],
  [22, 'Campeón'],
  [23, 'Criaturas'],
  [24, 'Kami'],
  [25, 'Xian'],
  [26, 'Tótem'],
  [27, 'Faerie / Goblin'],
  [28, 'Titán / Minotauro'],
  [29, 'Defensor / Druida'],
  [30, 'Sombra / Drácula'],
  [31, 'Triada Paladín'],
  [32, 'Triada Tenebris'],
  [33, 'Triada Desafiante']
])
